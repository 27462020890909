import React from 'react';
import * as PropTypes from 'prop-types';
import {Link, graphql} from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage } from "react-intl";


import Layout from '../components/layout';

import "../styles/GlossaryPage.scss";

const propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const GlossaryPage = ({data, location}) => {

  const allTerms = data.content.edges;

  return (
    <Layout data={data} location={location}>
      <article className="LandingPage Page">
        <section className="container grid-lg">
          <h1><FormattedMessage id="glossary" /></h1>
          <div className="cards">
            {allTerms.map((term, idx) => (
              <div className={`card color-5-alt`} key={idx}>
                <Link to={`/${term.node.node_locale}/terms/${term.node.slug}`}>
                  <h5>{term.node.title}</h5>
                </Link>
              </div>
            ))}
          </div>
        </section>
      </article>
    </Layout>
  );
};

GlossaryPage.propTypes = propTypes;

export default GlossaryPage;

export const glossaryPageFragment = graphql`
         fragment GlossaryPageFragment on ContentfulTermConnection {
           edges {
             node {
               node_locale
               title
               definition {
                 childMarkdownRemark {
                   html
                 }
               }
               slug
             }
           }
         }
       `;
